const $ = require('jquery');

$('.house__toggles a').on('click', function(){
  var $li = $(this).parent('li');
  var idx = $li.index();
  $('.house__toggles li').removeClass('active');
  $li.addClass('active');
  $('.house__floor').hide();
  $('.house__floor').eq(idx).show();
});
