var $ = require('jquery');
var isMobile = require('is-mobile');
var Cookies = require('tiny-cookie');

//require('browsernizr/test/geolocation');
//require('browsernizr/test/css/regions');
//require('browsernizr/test/css/animations');

require('./readmore');
require('./cookies');
require('./nav');
require('./blazy');
require('./tooltips');
require('./carousel');
require('./finder');
require('./videos');
require('./maps');
require('./contact-form');
require('./popups');
require('./htb');
require('./fhf');
require('./siteplans');
require('./house');
require('./homeselector');
require('./sorter');

if(!Cookies.get('urgentReadv2')) {
  $('.urgent').addClass('ready');
}

if($('#hs-form').length) {
  $.get('/health-safety-form', function(html) {
    $('#hs-form').html(html);
  });
}

if($('#santa-form').length) {
  $.get('/find-santa-form', function(html) {
    $('#santa-form').html(html);
  });
}

$('#urgent-esc').click(function() {
  $('.urgent').removeClass('ready');
  Cookies.set('urgentReadv2', true, { expires: 7 }); 
});

if(isMobile()) {
  $('.copy__readmore').readmore({
    moreLink: '<a class="copy__readmore-toggle" href="#"><span class="button button--readmore">Read more</span></a>',
    collapsedHeight: 300
  });
  $('.homesLink--hs:not(.homesLink--mobile-allow)').remove();
}
