var GoogleMapsLoader = require('google-maps');

GoogleMapsLoader.KEY = 'AIzaSyBTyDRN9eP6fM4ltyyTvXlsIkMrzqi53ss';
GoogleMapsLoader.VERSION = '3.31';

if($('#copy-map').length) {

  var lat = $('#copy-map').data('lat');
  var lng = $('#copy-map').data('lng');

  GoogleMapsLoader.load(function(google) {

    var map = new google.maps.Map(document.getElementById('copy-map'), {
      zoom: 9,
      center: {
        lat: typeof(lat) == 'undefined' ? 57.483 : parseFloat(lat),
        lng: typeof(lng) == 'undefined' ? -4.225 : parseFloat(lng)
      }
    });

    var marker = new google.maps.Marker({
      icon: "/images/icon-green.png",
      position: {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      },
      map: map
    });

  });
}

if($('#map').length) {

  var zoom = $('#map').data('zoom');
  var boxes = $('#map').data('boxes');

  if (typeof(zoom) == 'undefined') {
    zoom = 7;
  }

  var lat = $('#map').data('lat');
  var lng = $('#map').data('lng');

  var markers = [];
  var windows = [];

  GoogleMapsLoader.load(function(google) {

    var InfoBox = require('./infobox');

    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: zoom,
      center: {
        lat: typeof(lat) == 'undefined' ? 57.483 : lat,
        lng: typeof(lng) == 'undefined' ? -4.225 : lng
      }
    });

    for(var loc in window.locations) {

      var index = markers.length;

      markers[index] = new google.maps.Marker({
        icon: "/images/icon-green.png",
        position: {
          lat: window.locations[loc].lat,
          lng: window.locations[loc].lng
        },
        title: window.locations[loc].title,
        map: map
      });
  
      if(boxes == "yes") {

        (function () {
          
          function clearMarkers() {
            var markers_len = windows.length;
            
            for( i=0; i<markers_len; i++ ){
              windows[i].close();
            }
            for( i=0; i<markers_len; i++ ){
              if(markers[i].featured) markers[i].setIcon("/images/map-hotspot-on.png");
            }
          }
                    
          var image = window.locations[loc].image;

          var boxText = "<a href='/developments/" + window.locations[loc].slug + "' class='tippy-popper'><div class='tippy-tooltip dark-theme'><div class='tippy-content'>";

          if(window.locations[loc].image != ""){
            boxText = boxText + "<img src='" + window.locations[loc].image + "'>";
          }

          boxText = boxText + '<strong>' + window.locations[loc].title + '</strong>';

          if( window.locations[loc].bedrooms != "" ) {
            boxText = boxText + "<br />" + window.locations[loc].strapline;
          }

          if( window.locations[loc].fromPrice != "" ) {
            boxText = boxText + "<br><span class='tooltip__status'>from &pound;" + window.locations[loc].min + '</span>';
          }

          boxText = boxText + "</div></div></a>";

          var myOptions = {
               content: boxText
              ,disableAutoPan: false
              ,maxWidth: 0
              ,pixelOffset: new google.maps.Size(-110, -30)
              ,zIndex: null
              ,boxStyle: { 
                width: "220px"
               }
              ,closeBoxURL: ""
              ,infoBoxClearance: new google.maps.Size(0, 0)
              ,isHidden: false
              ,pane: "floatPane"
              ,enableEventPropagation: false
              ,alignBottom: true
            };
          
          var obj	= markers[index];
          
          var infowindow 	= new InfoBox(myOptions);
          obj.setZIndex(100);
          windows.push(infowindow);

          google.maps.event.addListener(obj, 'click', function(e) {
            clearMarkers();
            obj.position = e.latLng;
            infowindow.open(map, obj);
          });
          
          google.maps.event.addListener(map, 'click', function(e) {
            clearMarkers();
          });


        })();
      }
    }


  });

}
