var isMobile = require('is-mobile');

//var $hs = isMobile() ? $('.hs-popup', '.homesLink--mobile-allow') : $('.hs-popup');
//var $hs = isMobile() ? [] : $('.hs-popup');
var $hs = $('.hs-popup');


/*
if(!isMobile()) {
  $('.homesLink--hs').show();
}
*/

$('.homesLink--hs').show();

if($hs.length) {

  function bindEvent(element, eventName, eventHandler) {
      if (element.addEventListener) {
          element.addEventListener(eventName, eventHandler, false);
      } else if (element.attachEvent) {
          element.attachEvent('on' + eventName, eventHandler);
      }
  }

  $hs.each(function(idx){

    var $wrap = $(this);
    var src = $wrap.data('src');

    var $popup = $('.homeselector__popup', $wrap);
    $popup.append('<div class="homeselector__options"><div class="homeselector__close" id="homeselector-close-' + idx + '"></div></div>');

    $wrap.append($popup);

    $wrap.click(function(e) {
      e.preventDefault();
      e.stopPropagation();
      $popup.append('<iframe scrolling="no" src="' + src + '" style="width:100%; height:100%;border:none;position:relative;"></iframe>');
      $popup.addClass('homeselector__popup--visible');
    });

    function closePopup(e) {
      e.preventDefault();
      e.stopPropagation();
      $popup.removeClass('homeselector__popup--visible');
    }

    $wrap.on('close', closePopup);

    $('#homeselector-close-' + idx).click(closePopup);

    window.onkeydown = function(e) {
      var e = e || window.event;
      if (e.keyCode == 27) {
        $wrap.trigger('close');
      }
    };

    //window.addEventListener('popupMessage', closePopup, false);

    bindEvent(window, 'close', closePopup);


  });

}

