var fitText = require('FitText-UMD');
require('owl.carousel');
require('owl.carousel2.thumbs');

if($('.owl-carousel--couple').length) {
  var owl = $('.owl-carousel--couple');

  owl.owlCarousel({
    items: 2,
    loop: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 3000,
    dotsContainer: '#heroDots',
    thumbs: true,
    thumbsPrerendered: true,
    animateOut: 'fadeOut',
    autoplayHoverPause: true,
    onInitialized: function(){
      window.bLazy.revalidate();
      window.bLazy.load($('.b-lazy', '.owl-carousel'), true);
    }
  });

  $('.hero__thumb, .owl-dot').hover(
    function(){
      owl.trigger('stop.owl.autoplay');
    },
    function(){
      owl.trigger('play.owl.autoplay');
    }
  );
}
else {
  var owl = $('.owl-carousel');

  owl.owlCarousel({
    items: 1,
    loop: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 3000,
    dotsContainer: '#heroDots',
    thumbs: true,
    thumbsPrerendered: true,
    animateOut: 'fadeOut',
    autoplayHoverPause: true,
    onInitialized: function(){
      window.bLazy.revalidate();
      window.bLazy.load($('.b-lazy', '.owl-carousel'), true);
      $('.hero__wrap').css({ 'height': 'auto', 'padding': '0' });
    }
  });
  $('.hero__thumb, .owl-dot').hover(
    function(){
      owl.trigger('stop.owl.autoplay');
    },
    function(){
      owl.trigger('play.owl.autoplay');
    }
  );
}


