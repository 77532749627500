var responsiveNav = require('responsive-nav');
var Sticky = require('sticky-js');

var sticky = new Sticky('.alt-nav-wrap');

var nav = responsiveNav(".nav-collapse", {
  customToggle: 'nav-toggle',
  close: function() {
    $(".nav-collapse").removeClass('dropdown-open');
  }
});

$('.nav-toggle').click(function(){
  $(".nav-collapse").removeClass('dropdown-open');
});

var subnav = responsiveNav(".subnav-collapse", {
  label: "<span class='icon icon--down'></span>",
  open: function() {
    $("#main-nav").addClass('dropdown-open');
    nav.resize();
  },
  close: function() {
    $("#main-nav").removeClass('dropdown-open');
  }
});

if($(".alt-nav-collapse").length > 0)
{
  var subnav = responsiveNav(".alt-nav-collapse", {
    label: "<span>Menu</span>",
    navClass: "alt-nav-collapse",
    customToggle: "#alt-nav-toggle",
    open: function(){
      $("#alt-nav-toggle").addClass("open");
    },
    close: function(){
      $("#alt-nav-toggle").removeClass("open");
    }
  });
}

