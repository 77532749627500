var svgPanZoom = require('svg-pan-zoom');
var isMobile = require('is-mobile');
var Hammer = require('hammerjs');
window.zooms = [];

if(!isMobile()) {
  $('.siteplan--mobile').remove();
}
else {
  $('.siteplan--desktop').remove();
}

if($('.siteplan__image--zoomable').length && isMobile()) {
  $('.siteplan__image--zoomable').each(function(){
    var sid = $(this).attr('id');
    window.zooms.push(
      svgPanZoom(this, {
        maxZoom: 3,
        minZoom: 1,
        onZoom: function(e) {
          repositionPlots(e, this, sid);
        },
        onPan: function(e) {
          repositionPlots(e, this, sid);
        },
        customEventsHandler: {
          haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
          init: function(options) {
            var instance = options.instance,
                initialScale = 1,
                pannedX = 0,
                pannedY = 0

            this.hammer = Hammer(options.svgElement, {
              inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
            });

            this.hammer.get('pinch').set({enable: true});

            this.hammer.on('doubletap', function(ev){
              instance.zoomIn()
            });

            this.hammer.on('tap', function(ev){
              //ev.preventDefault();
              //ev.srcEvent.stopPropagation();
            });

            this.hammer.on('panstart panmove', function(ev){
              // On pan start reset panned variables
              if (ev.type === 'panstart') {
                pannedX = 0
                pannedY = 0
              }

              // Pan only the difference
              instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY})
              pannedX = ev.deltaX
              pannedY = ev.deltaY
            });

            // Handle pinch
            this.hammer.on('pinchstart pinchmove', function(ev){
              // On pinch start remember initial zoom
              if (ev.type === 'pinchstart') {
                initialScale = instance.getZoom()
                instance.zoom(initialScale * ev.scale)
              }

              instance.zoom(initialScale * ev.scale)

            });

            options.svgElement.addEventListener('touchmove', function(e){ e.preventDefault(); });
          },
          destroy: function(){
            this.hammer.destroy();
          }
        }
      })
    );

    $('.plot-status').click(function(e){
      e.preventDefault();
      e.stopPropagation();
    });

  });
}



function repositionPlots(e, zoom, sid) {

  console.log(e, zoom, sid);

  var sizes = zoom.getSizes();
  var pan = zoom.getPan();
  var theZoom = zoom.getZoom();

  var $plots = $('.plot-status')
    .filter(function(){ return $(this).data('siteplan') == sid; })


  var $sp = $('#' + sid);

  var siteplan = {
    width: $sp.width(),
    height: $sp.width() * $sp.parent('.siteplan__map').eq(0).data('ratio')
  }

  $plots.each(function(){

    var dims = {
      transX: 0,
      transY: 0,
      percX: 0,
      percY: 0
    }

    var that = this;

      var x = $(that).position().left,
          y = $(that).position().top;

      dims.percX = ($(that).data('percx')/100);
      dims.percY = ($(that).data('percy')/100);

      dims.transX = pan.x + ((siteplan.width * theZoom) * dims.percX) - (siteplan.width * dims.percX);
      dims.transY = pan.y + ((siteplan.height * theZoom) * dims.percY) - (siteplan.height * dims.percY);

      $(that).css({
        transform: 'translate(' +
          dims.transX + 'px, ' +
          dims.transY + 'px)'
      });

  });
}

