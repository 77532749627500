var Modernizr = require('browsernizr');
var isMobile = require('is-mobile');
require('parsleyjs');
var cssmodal = require('css-modal');

var $main,
    $wrap,
    $pages,
    pagesCount,
    current,
    isAnimating = false,
    endCurrPage = false,
    endNextPage = false;

if($('#inline-contact-form').length) {
  init();
}

if($('#form-enquiry').length) {
  $.get('/contact/enquiry-form-fields', function(html) {
    $('#form-enquiry').html(html);
  });
}
if($('#form-fhf-enquiry').length) {
  $.get('/offers/fhf-enquiry-form-fields', function(html) {
    $('#form-fhf-enquiry').html(html);
  });
}
if($('#form-ringback').length) {
  $.get('/contact/ringback-form-fields', function(html) {
    $('#form-ringback').html(html);
  });
}

$('#form-next').click(function(e){
  e.preventDefault();
  e.stopPropagation();
  $('#contact-form').parsley().whenValidate({
    group: 'block-' + $pages.index($pages.filter('.pt-page-current')),
    force: true
  }).done(function(){
    console.log(current);
    nextPage({ move: 'next', animation: 1 });
  });
});

$('#form-back').click(function(e){
  e.preventDefault();
  e.stopPropagation();
  nextPage({ move: 'prev', animation: 2 });
});

$('#form-submit').click(function(e){
  e.preventDefault();
  e.stopPropagation();
  $('#contact-form').parsley().whenValidate().done(function(){
    $('#contact-form').submit(); 
  });
});

$('body').on('click', '#wants input[type=checkbox]', function() {
  var page;
  if(page = $(this).data('page')) {
    console.log(page);
    if($(this).prop('checked')) {
      $('#' + page).addClass('pt-page');
      $('#' + page).data( 'originalClassList', $('#' + page).attr( 'class' ) );
    }
    else {
      $('#' + page).removeClass('pt-page');
    }
    $pages = $('#contact-form').children( 'div.pt-page' ),
    pagesCount = $pages.length;
  }
});

$('body').on('click', '#dummy-wants input[type=checkbox]', function() {
  $('#' + $(this).data('pair')).click();
});

$('body').on({
  submit: function () {
    var $self = $(this);
    var brochure = false;
    $("button[type=submit]", $self).prop("disabled", true);
    $self.hide();
    $('.modal footer').hide();
    $("#form-loading").show();

    $.ajax({
      type: "post",
      dataType: "json",
      data: $(this).serialize(),
      success: function (response) {

        if (response.success && response.finished) {
            $("#form-loading").hide();
            var wants = [];

            $.each($("input[name='wants[]']:checked"), function(idx) {
              if($(this).val() != "brochure") {
                wants.push($(this).val());
              }
              else {
                brochure = true;
              }
              $('#want-' + $(this).val()).show();
            });

            $self[0].reset();

            if(brochure) {
              $('#wants-summary').after('<p class="form__download"><a target="_blank" href="' + $('#brochureUrl').val() + '"><span class="icon icon--file"></span> Download the brochure</a></p>');
            }

            if(wants.length) {
              $('#wants-heading').show();
            }

            $('#form-summary').show();

        } else if (response.errors) {
            for (var key in response.errors) {
                if (!response.errors.hasOwnProperty(key)) continue;
                var messages = response.errors[key];
                var $errors  = $("<ul>", {"class": "errors help-block"});
                for (var i = 0; i < messages.length; i++) {
                    var $li = $("<li>");
                    $li.html(messages[i]);
                    $errors.append($li);
                }

                var $input = $("*[name=" + key + "], *[name='" + key + "[]']");

                var $formGroup = $input.parents(".form-group");
                $formGroup.addClass("has-error");

                $formGroup.append($errors);
            }
        }

        $("button[type=submit]", $self).prop("disabled", false);
      }
    });

    return false;
  }

}, '#contact-form');

$('body').on('click', '.modal-close, .button--close', function (event) {
  $('#form-summary, #form-loading').hide();
  $('#wants-heading').hide();
  $('li', '#wants-summary').hide();
  $('.form__download').remove();
  resetPage( $pages.eq(current), $pages.eq(0) );
  $('#contact-form')[0].reset();
  $('#contact-form').show().parsley().reset();

  if($('#dummy-form').length) {
    $('#dummy-form')[0].reset();
  }

  $('.modal footer, #form-next').show();
  $('#form-back, #form-submit').hide();
  current = 0;

  $('#inline-contact-form').empty();
  init();
            
});

$('body').on('change', '#contact-form', function(){
  $('#contact-form').parsley().whenValid({
    group: 'block-' + $pages.index($pages.filter('.pt-page-current')),
    force: true
  }).fail(function(){
    $('#form-next, #form-submit').addClass('disabled'); 
  }).done(function(){
    $('#form-next, #form-submit').removeClass('disabled'); 
  });
});

$('body').on('click','#form-open', function(e){
  nextPage({ move: 'next', animation: 1 });
  return true;
});


function init() {

  if(document.getElementById('inline-contact-form')) {
    var dev = $('#inline-contact-form').data('dev');
    $.get('/contact/contact-form-fields' + (dev != "" ? ('?dev=' + dev) : ''), function(html) {
      $('#inline-contact-form').html(html);

      $main = $( '#contact-form' );
      $wrap = $('#contact');
      $pages = [];
      pagesCount = 0;
      current = 0;

      $main.addClass('form--modal');

      var honeypotValue = $('[id^=freeform_form_handle]')[0].value;
      $wrap.addClass('modal modal--fade');
      
      $pages = $('#contact-form').children( 'div.pt-page' ),
      pagesCount = $pages.length,

      $pages.each( function() {
        var $page = $( this );
        $page.removeClass('pt-page-current');
        $page.data( 'originalClassList', $page.attr( 'class' ) );
      } );

      $pages.eq( current ).addClass( 'pt-page-current' );

      $('.form__page').each(function(index, page) {
        $(page).find(':input').attr('data-parsley-group', 'block-' + index);
      });

    });
  }

}

function nextPage(options) {

  var animation = (options.animation) ? options.animation : options;

  if( isAnimating ) {
    return false;
  }

  isAnimating = true;

  var $currPage = $pages.eq( current );

  if(typeof options.showPage != 'undefined'){
    if( options.showPage < pagesCount - 1 ) {
      current = options.showPage;
    }
    else {
      current = 0;
    }
  }
  else{
    if(options.move == 'next') {
      if( current < pagesCount - 1 ) {
        ++current;
        $('#form-back').show();
      }
    }
    else if(options.move == 'prev') {
      --current;
      if( current <= 0 ) {
        current = 0;
        //$('.form__button--back').remove();
        $('#form-back').hide();
      }
      else {
        $('#form-next').show();
        $('#form-submit').hide();
      }
    }
    else {
      current = 0;
    }
  }

  if( current == pagesCount - 1 ) {
    $('#form-submit').show();
    $('#form-next').hide();
  }

  var $nextPage = $pages.eq( current ).addClass( 'pt-page-current' ),
    outClass = '', inClass = '';

  switch( animation ) {

    case 1:
      outClass = 'pt-page-moveToLeft';
      inClass = 'pt-page-moveFromRight';
      break;
    case 2:
      outClass = 'pt-page-moveToRight';
      inClass = 'pt-page-moveFromLeft';
      break;
  }

  onEndAnimation( $currPage, $nextPage );

  $('#contact-form').parsley().whenValid({
    group: 'block-' + $pages.index($pages.filter('.pt-page-current')),
    force: true
  }).fail(function(){
    $('#form-next, #form-submit').addClass('disabled'); 
  }).done(function(){
    $('#form-next, #form-submit').removeClass('disabled'); 
  });
}

function onEndAnimation( $outpage, $inpage ) {
  endCurrPage = false;
  endNextPage = false;
  resetPage( $outpage, $inpage );
  isAnimating = false;
}

function resetPage( $outpage, $inpage ) {
  $outpage.attr( 'class', $outpage.data( 'originalClassList' ) );
  $inpage.attr( 'class', $inpage.data( 'originalClassList' ) + ' pt-page-current' );
}

if($('#contact-form').length) {
  init();
}

$('.copy__option').click(function(){
  var tab = $(this).data('tab');
  $('.form-tab').hide();
  $('#' + tab).show();
});

$('#dev-details-form').on('change', function(e){
  var dev = $('#development-details').val();
  $('.details-tab').hide();
  $('#details-' + dev).show();
});
