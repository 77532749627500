if(document.getElementById('fhf-calculator')) {

  var noUiSlider = require('nouislider');
  var _ = require('lodash/core');
  var array = require('lodash/array');

  var HTB = {

    els: [
      {
        slider: "#htb-deposit",
        textInput: "#htb-deposit-amt",
        listeners: ["#htb-deposit-total"],
        initValue: 20000,
        min: 7500,
        max: 195000,
        minThresh: 7500,
        bottom: 7500,
        step: 500,
        pips: {
          min: 10000,
          max: 125000
        }
        //perc: 5
      },
      {
        slider: "#htb-price",
        textInput: "#htb-price-amt",
        listeners: ["#htb-price-total"],
        initValue: 200000,
        min: 150000,
        max: 300000,
        minThresh: 150000,
        bottom: 150000,
        step: 1000,
        pips: {
          min: 150000,
          max: 300000
        }
        //perc: 80
      }
    ],

    formatMoney: function(num, decPlaces, thouSeparator, decSeparator) {
        var n = num,
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSeparator = decSeparator == undefined ? "." : decSeparator,
        thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
        sign = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
        return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
    },

    update: function(val, oel, el)
    {
      var mult, left, round, roundFormat;
      var self = this;

      roundFormat = self.formatMoney(oel.val, 0, ",");

      //document.querySelector(oel.slider).noUiSlider.set(round);
      $(oel.textInput).val(roundFormat).attr('data-int', val)

      _.each(oel.listeners, function(listener){
        $(listener).html(roundFormat);
      });

      el.currentValue = val;

      self.totalUp();
    },

    totalUp: function()
    {
      var self = this;

      $("#htb-mortgage-total").add("span", "#htb-summary-total").html(
        self.formatMoney(($('#htb-price-amt').attr('data-int') - $('#htb-deposit-amt').attr('data-int') - 25000), 0)
      );

    },

    adjustVals: function(slider, el, values, handle)
    {
      var self = this;
      var val = Number(values[0]) < el.minThresh ? el.bottom : Number(values[0]);
      var deposit = document.getElementById('htb-deposit').noUiSlider;
      var price = document.getElementById('htb-price').noUiSlider.get();

      $( el.textInput )
        .val( self.formatMoney(val, 0))
        .attr('data-int', val);

      _.each(el.listeners, function(listener){
        $(listener).html( self.formatMoney(val, 0) );
      });

      if( deposit.get() < (price * 0.05)) {
        deposit.set(price * 0.05);
        $('#htb-deposit-total').html(self.formatMoney(deposit.get(), 0, ","));
      }
      if( deposit.get() > (price-(price*.25)-25000)) {
        deposit.set(price-(price*.25)-25000);
        $('#htb-deposit-total').html(self.formatMoney(deposit.get(), 0, ","));
      }

      deposit.updateOptions({
        pips: {
          mode: 'values',
          density: 5,
          min: price*.5,
          max: (price-(price*.25)-25000)
        }
      }, true);

      _.each( array.without(self.els, el), function(oel){
        var slider = document.querySelector(oel.slider).noUiSlider;
        $(oel.textInput).val(self.formatMoney(slider.get(), 0, ",")).attr('data-int', slider.get())
      });

      //$('#htb-deposit-amt').val(self.formatMoney(deposit.get(), 0, ",")).attr('data-int', deposit.get());
      //$('#htb-deposit-total').val(self.formatMoney(deposit.get(), 0, ","));

      self.totalUp();
    },

    init: function()
    {
      var self = this;
        
      _.each( HTB.els, function(el){

        $(el.textInput).val( self.formatMoney(el.bottom, 0) ).attr('data-int', el.bottom);

        _.each(el.listeners, function(listener){
          $(listener).html( self.formatMoney(el.bottom, 0) );
        });

        var slider = document.querySelector(el.slider);

        noUiSlider.create(document.querySelector(el.slider), {
          range: {
            'min': el.min,
            'max': el.max
          },
          /*
          pips: {
            mode: 'values',
            values: [el.pips.min, el.pips.max],
            density: 10
          },
         */
          start: el.initValue,
          step: el.step,
          handles: 1
        })
        .on('slide', function(values, handle) {
          self.adjustVals(this, el, values, handle);
        });

        slider.noUiSlider
          .on('change', function(values, handle) {
            self.adjustVals(this, el, values, handle);
          });

        $(el.textInput).change(function(){

          var val = $(this).val().replace(",", "");
          val = (val < el.bottom) ? el.bottom : val;
          val = (val > el.max) ? el.max : val;
          $(el.slider).val(val);
          $(el.slider)[0].noUiSlider.set(val);

          self.adjustVals(this, el, [val]);

          _.each(el.listeners, function(listener){
            $(listener).html( self.formatMoney(val, 0) );
          });

          _.each( array.without(HTB.els, el), function(oel){
            //self.update(val, oel, el);
          });

          $(this).val( self.formatMoney(val, 0) );

          self.totalUp();

          $(this).blur();

        });

      });

      _.each( HTB.els, function(el){
        var slider = document.querySelector(el.slider);
        self.adjustVals(el.slider, el, [slider.noUiSlider.options.start]);
      });

      self.totalUp();

    }

  }

  HTB.init();

}
