require('cookieconsent');

window.addEventListener("load", function(){
  cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#0f7140"
      },
      "button": {
        "background": "#74bf6b",
        "text": "#fff"
      }
    },
    "type": "opt-out",
    "content": {
      "dismiss": "Agree",
      "deny": "Disagree",
      "href": "/privacy"
    },
  elements: {
      
      message: '<span id="cookieconsent:desc" class="cc-message" style="color:#fff;">We use cookies to ensure you get the best experience on our website.</span>',
      messagelink: '<span id="cookieconsent:desc" class="cc-message">We use cookies to ensure you get the best experience on our website.<a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="/cookie-policy">Learn More</a></span>',
      
      allow: '<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow">Agree</a>',
      deny: '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny" href="nocookies">Disagree</a>',
      
    }
  })
});
