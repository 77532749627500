var $ = require('jquery');

$('select', '.sorter').on('change', function(){

  var val = $(this).val();


  if(val == "") {
    $('.cards li').fadeIn('fast');
  }
  else {
    var $hide = $('.cards li').filter(function(){
      return $(this).data('area') != val;
    });
    var $show = $('.cards li').filter(function(){
      return $(this).data('area') == val;
    });
    if($show.length == 1) {
      $('.cards').css('min-height', $show.eq(0).height());
    }
    $hide.fadeOut(100);
    setTimeout(
      function() {
        $('.cards li').removeClass('no-left-margin');
        $show.fadeIn(200);
        if($show.length == 1) {
          $show.eq(0).addClass('no-left-margin');
        }
      },
      100
    );
  }
});
