require('magnific-popup');

$('.htb-link').magnificPopup({
  type:'inline',
  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});

$('.fhf-link').magnificPopup({
  type:'inline',
  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});


$('.video-popup').magnificPopup({
  type: 'iframe',
  midClick: true 
});

$('.floorplan--diagram').magnificPopup({
  type: 'image'
});
