var Blazy = require('blazy');

window.bLazy = new Blazy({
  loadInvisible: true,
  breakpoints: [
    {
      width: 640,
      src: 'data-src-mobile'
    },
    {
      width: 960,
      src: 'data-src-tablet'
    },
    {
      width: 400,
      src: 'data-src-mini'
    }
  ]
});

