var tippy = require('tippy.js');
var template = $('<div id="maTemplate">Template</div>').appendTo('body').hide();

tippy('.plot-status', {
  allowTitleHtml: true,
  popperOptions: {
    positionFixed: true
  },
  html: '#maTemplate',
  duration: 0,
  arrow: true,
  createPopperInstanceOnInit: true,
  hideOnClick: 'persistent',
  interactive: true,
  onShow(tpy) {
    var data = $(tpy.reference).data();
    var htmlOpen = data.status == "available" ? '<a class="plot-tooltip" href="' + data.url + '">' : '<div class="plot-tooltip">';
    var htmlClose = data.status == "available" ? '</a>' : '</div>';
    $('.tippy-content', this).html(
      htmlOpen
      + '<img src="' + data.image + '"><strong>' + data.gen + ' - Plot ' + data.number + '</strong><br>'
      + data.strapline + '<br>'
      + (typeof(data.showhome) !== "undefined" && data.showhome != "" ? '<span class="tooltip__highlight">' + data.showhome + '</span>' : '')
      + '<span class="tooltip__status">' + data.statusText + '</span>'
      + htmlClose
     );
  }
});
