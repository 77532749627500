//var Modernizr = require('browsernizr');

function checkDevelopment() {
  var $dev = $('#development').find(':selected');
  var $area = $('#area').find(':selected');
  var $devs;
  var $devsHide;

  $('option', '#development').remove();
  $devsMaster.appendTo($('#development'));

  $('#development option').prop('selected', function() {
    return this.defaultSelected;
  });

  if($area.eq(0).val()) {
    $devs = $('option', '#development');

    if(typeof(this) != "undefined" && $dev.data('area') != this.value) {
      $dev.prop('selected', false);
    }

    $devsHide = $devs.filter(function(idx, d) {
      return $(d).val() && $(d).data('area') != $area.val();
    });

    $devsHide.remove();

    if($('option', '#development').length == 2) {
      $('option', '#development').eq(1).prop('selected', true);
    }
  }
}

var $devsMaster = $('option', '#development').clone();

$('#development').on('change', function(){

  var $dev = $('#development').find(':selected');
  var $area = $('#area').find(':selected');
  $area.prop('selected', false);
  $('option[value=' + $dev.data('area') + ']').prop('selected', true);

});

$('#area').on('change', checkDevelopment);

checkDevelopment();

$('.search__again').click(function(e) {
  e.stopPropagation();
  e.preventDefault();
  $('#search-fields').slideDown();
  $('.search__heading').show();
  $('#search-again').hide();
});

// Geolocation

if (window.Modernizr.geolocation) {

  $('#search-submit').removeClass('button--full');
  $('.mobile-show').show();
  
  $('#near-me').click(function(e) {

    e.preventDefault();
    e.stopPropagation();

    $(this).html('Finding you...');

    /* PRODUCTION */
    var position = navigator.geolocation.getCurrentPosition(function(loc) {
      $('#area', '#search-form').prop('selectedIndex', 0);
      $('#development', '#search-form').prop('selectedIndex', 0);
      $('#search-form').append('<input type="hidden" name="lat" value="' + loc.coords.latitude + '">');
      $('#search-form').append('<input type="hidden" name="lng" value="' + loc.coords.longitude + '">');
      $('#search-form').submit();
    });
    
  });

}

