if(document.getElementById('htb-calculator')) {

  var noUiSlider = require('nouislider');
  var _ = require('lodash/core');
  var array = require('lodash/array');

  var HTB = {

    els: [
      {
        slider: "#htb-deposit",
        textInput: "#htb-deposit-amt",
        listeners: ["#htb-deposit-total"],
        initValue: 5000,
        min: 5000,
        max: 10000,
        minThresh: 5000,
        bottom: 5000,
        step: 500,
        perc: 5
      },
      {
        slider: "#htb-loan",
        textInput: "#htb-loan-amt",
        listeners: ["#htb-loan-total"],
        initValue: 15000,
        min: 15000,
        max: 30000,
        minThresh: 15000,
        bottom: 15000,
        step: 500,
        perc: 15
      },
      {
        slider: "#htb-mortgage",
        textInput: "#htb-mortgage-amt",
        listeners: ["#htb-mortgage-total"],
        initValue: 80000,
        min: 80000,
        max: 160000,
        minThresh: 80000,
        bottom: 80000,
        step: 1000,
        perc: 80
      }
    ],

    formatMoney: function(num, decPlaces, thouSeparator, decSeparator) {
        var n = num,
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSeparator = decSeparator == undefined ? "." : decSeparator,
        thouSeparator = thouSeparator == undefined ? "," : thouSeparator,
        sign = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
        return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
    },

    update: function(val, oel, el)
    {
      var mult, left, round, roundFormat;
      var self = this;

      mult = oel.perc / el.perc;
      round = Math.round( val * mult );
      roundFormat = self.formatMoney(round, 0, ",");

      document.querySelector(oel.slider).noUiSlider.set(round);
      $(oel.textInput).val(roundFormat).attr('data-int', round)

      _.each(oel.listeners, function(listener){
        $(listener).html(roundFormat);
      });

      el.currentValue = round;

      self.totalUp();
    },

    totalUp: function()
    {
      var self = this;

      $("span", "#htb-total").add("span", "#htb-summary-total").html(
        self.formatMoney(
          _.reduce(
            _.map(HTB.els, function(mel){
              return Number($(mel.textInput).attr('data-int'));
            }), function(memo, num){
              return memo + num;
            }
          ), 0
        )
      );

    },

    adjustVals: function(slider, el, values, handle)
    {
      var self = this;
      var val = Number(values[0]) < el.minThresh ? el.bottom : Number(values[0]);
      $( el.textInput )
        .val( self.formatMoney(val, 0))
        .attr('data-int', val);

      _.each(el.listeners, function(listener){
        $(listener).html( self.formatMoney(val, 0) );
      });

      _.each( array.without(self.els, el), function(oel){
        self.update(val, oel, el);
      });
    },

    init: function()
    {
      var self = this;
        
      _.each( HTB.els, function(el){

        $(el.textInput).val( self.formatMoney(el.bottom, 0) ).attr('data-int', el.bottom);

        _.each(el.listeners, function(listener){
          $(listener).html( self.formatMoney(el.bottom, 0) );
        });

        var slider = document.querySelector(el.slider);

        noUiSlider.create(document.querySelector(el.slider), {
          range: {
            'min': el.min,
            'max': el.max
          },
          start: el.initValue,
          step: el.step,
          handles: 1
        })
        .on('slide', function(values, handle) {
          self.adjustVals(this, el, values, handle);
        });

        $(el.textInput).change(function(){

          var val = $(this).val().replace(",", "");
          val = (val < el.bottom) ? el.bottom : val;
          val = (val > el.max) ? el.max : val;
          $(el.slider).val(val);

          _.each(el.listeners, function(listener){
            $(listener).html( self.formatMoney(val, 0) );
          });

          _.each( array.without(HTB.els, el), function(oel){
            self.update(val, oel, el);
          });

          $(this).val( self.formatMoney(val, 0) );

          $(this).blur();

        });

      });

      _.each( HTB.els, function(el){
        var slider = document.querySelector(el.slider);
        self.adjustVals(el.slider, el, [slider.noUiSlider.options.start]);
      });

      self.totalUp();

    }

  }

  if($('#htb-calculator').length) {
    HTB.init();
  }
}
